module.exports = [
	{
		id: 'start-date',
		type: 'start-date',
		default: true,
		exclude: ['/404/', '/', '/thank-you/', '/dc/', '/dnp/'],
	},
	{
		id: 'new-layout',
		type: 'new-layout',
		default: true,
		include: ['/dc/', '/dnp/'],
	},
];
